<template>
  <div>
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-row class="mt-1">
        <b-col lg="5" md="6" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Web Blok Listesi</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-0">
            <table class="table table-striped mt-1">
              <thead>
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  BLOK TÜRÜ
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  BLOK BAŞLIĞI
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"/>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(blok, index) in salaryListItems"
                  :key="index"
              >
                <td>{{ blokTur(blok.blokTuru) }}</td>
                <td>
                  {{ blok.baslik }}
                </td>
                <td>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.blokEdit
                      variant="bgInfo"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                      @click="editOn(blok.blokID)"
                  >
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="bgDanger"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                      @click="blokSil(blok.blokID)"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card-actions>
        </b-col>
        <b-col lg="7" md="6" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
              :key="count"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Blok Kaydı</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-0">
            <b-row class="mt-2">
              <b-col cols="12">
                <b-form-group label="Blok Türü">
                  <v-select
                      v-model="blokTuru"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="option"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Fotoğraf Konumu">
                  <v-select
                      v-model="fotoKonum"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="fotoOption"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4" sm="12" md="12" cols="12">
                <b-form-group
                    label="Blok Fotoğrafı"
                    label-for="basicInput"
                >
                  <b-form-file
                      type="file"
                      placeholder="Dosya Seçiniz"
                      drop-placeholder="Drop file here..."
                      accept=".jpg, .png, .gif, .jpeg"
                      :file-name-formatter="formatNames"
                      @change="onFileSelected"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4" sm="6" md="12" cols="12">
                <b-form-group
                    label="Fotoğraf Genişliği"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model.number="fotoGenislik"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4" sm="6" md="12" cols="12">
                <b-form-group
                    label="Fotoğraf Yüksekliği"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model="fotoYukseklik"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Blok Başlığı"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model="baslik"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="İçerik"
                    label-for="basicInput"
                >
                  <quill-editor
                      v-model="content"
                      :options="snowOption"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Link"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model="blokLink"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Link Yazısı"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model="linkYazisi"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="10">
                <b-progress
                    v-model="value"
                    max="100"
                />
              </b-col>
              <b-col cols="2">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="bgInfo"
                    class="mt-1 mr-1 border-0"
                    :style="{backgroundColor:$store.getters.bgSuccess}"
                    @click="onUpload"
                >
                  Kaydet
                </b-button>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </div>
    <b-modal
        id="blokEdit"
        ok-only
        centered
        size="lg"
        hide-footer
        @hide="editHide"
    >
      <b-row>
        <b-col cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Blok Düzenle</h4>
                </b-col>
              </div>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <b-form-group label="Blok Türü">
                  <v-select
                      v-model="editBlokTuru"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="option"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Fotoğraf Konumu">
                  <v-select
                      v-model="editFotoKonum"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="fotoOption"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4" sm="6" md="12" cols="12">
                <b-form-group
                    label="Blok Fotoğrafı"
                    label-for="basicInput"
                >
                  <b-form-file
                      type="file"
                      placeholder="Dosya Seçiniz"
                      drop-placeholder="Drop file here..."
                      accept=".jpg, .png, .gif, .jpeg"
                      :file-name-formatter="editFormatNames"
                      @change="editOnFileSelected"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4" sm="6" md="12" cols="12">
                <b-form-group
                    label="Fotoğraf Genişliği"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model.number="editFotoGenislik"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4" sm="6" md="12" cols="12">
                <b-form-group
                    label="Fotoğraf Yüksekliği"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model.number="editFotoYukseklik"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Blok Başlığı"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model="editBaslik"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="İçerik"
                    label-for="basicInput"
                >
                  <quill-editor
                      v-model="editContent"
                      :options="snowOption"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Link"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model="editBlokLink"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Link Yazısı"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model="editLinkYazisi"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-actions>
          <b-row>
            <b-col cols="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1 border-0"
                  variant="bgInfo"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="editSave"
              >
                Güncelle
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1"
                  variant="bgDanger border-0 text-white"
                  :style="{backgroundColor:$store.getters.bgDanger}"
                  @click="editHide()"
              >
                İptal
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BProgress,
  BRow,
  BSpinner,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile,
} from 'bootstrap-vue'

export default {
  components: {
    BCardActions,
    BProgress,
    BButton,
    BSpinner,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    BFormFile,
    vSelect,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      count: 0,
      spinner: true,
      Deselect: {
        render: createElement => createElement('span', ''),
      },
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      currentID: '',
      snowOption: {
        placeholder: 'İçerik Giriniz',
      },
      content: '',
      baslik: '',
      blokLink: '',
      linkYazisi: '',
      fotoGenislik: '570',
      fotoYukseklik: '300',
      blokTuru: {
        title: 'Ana Sayfa',
        id: 1,
      },
      option: [
        {
          title: 'Ana Sayfa',
          id: 1
        },
        {
          title: 'Hakkımızda',
          id: 2
        },
        {
          title: 'İletişim',
          id: 3
        },
      ],
      fotoKonum: {
        title: 'Sol',
        id: 1,
      },
      fotoOption: [
        {
          title: 'Sol',
          id: 1
        },
        {
          title: 'Sağ',
          id: 2
        },
      ],
      value: 0,
      selectedFile: [],
      editSelectedFile: [],
      editFotoGenislik: '570',
      editFotoYukseklik: '300',
    }
  },
  computed: {
    editBlokTuru: {
      get() {
        return this.$store.state.webPanel.blokEdit.blokTuru == 1
            ? 'Ana Sayfa'
            : '' || this.$store.state.webPanel.blokEdit.blokTuru == 2
                ? 'Hakkımızda'
                : '' || this.$store.state.webPanel.blokEdit.blokTuru == 3
                    ? 'İletişim'
                    : ''
      },
      set(val) {
        this.$store.state.webPanel.blokEdit.blokTuru = val.id.toString()
      },
    },
    editFotoKonum: {
      get() {
        return this.$store.state.webPanel.blokEdit.fotoKonum == 1
            ? 'Sol'
            : '' || this.$store.state.webPanel.blokEdit.fotoKonum == 2
                ? 'Sağ'
                : ''
      },
      set(val) {
        this.$store.state.webPanel.blokEdit.fotoKonum = val.id.toString()
      },
    },
    editBaslik: {
      get() {
        return this.$store.state.webPanel.blokEdit.baslik
      },
      set(val) {
        this.$store.state.webPanel.blokEdit.baslik = val
      },
    },
    editContent: {
      get() {
        return this.$store.state.webPanel.blokEdit.icerik
      },
      set(val) {
        this.$store.state.webPanel.blokEdit.icerik = val
      },
    },
    editBlokLink: {
      get() {
        return this.$store.state.webPanel.blokEdit.link
      },
      set(val) {
        this.$store.state.webPanel.blokEdit.link = val
      },
    },
    editLinkYazisi: {
      get() {
        return this.$store.state.webPanel.blokEdit.linkBaslik
      },
      set(val) {
        this.$store.state.webPanel.blokEdit.linkBaslik = val
      },
    },
    blokTur() {
      return tur => (tur == 1
          ? 'Ana Sayfa'
          : '' || tur == 2
              ? 'Hakkımızda'
              : '' || tur == 3
                  ? 'İletişim'
                  : '')
    },
    salaryListItems() {
      return this.$store.state.webPanel.blok.slice()
          .reverse()
    },
  },
  mounted() {
  },
  created() {
    this.$store
        .dispatch('blok')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearBlok')
  },
  methods: {
    editSave() {
      const fd = new FormData()
      fd.append('resim', this.editSelectedFile[0] || '')
      fd.append('crm_token', localStorage.getItem('otelUserToken'))
      fd.append('serviceName', 'web')
      fd.append('methodName', 'blokYonetimi')
      fd.append('blokTuru', this.$store.state.webPanel.blokEdit.blokTuru)
      fd.append('fotoKonum', this.$store.state.webPanel.blokEdit.fotoKonum)
      fd.append('g', this.editFotoGenislik)
      fd.append('y', this.editFotoYukseklik)
      fd.append('baslik', this.$store.state.webPanel.blokEdit.baslik)
      fd.append('link', this.$store.state.webPanel.blokEdit.link)
      fd.append('icerik', this.$store.state.webPanel.blokEdit.icerik)
      fd.append('linkBaslik', this.$store.state.webPanel.blokEdit.linkBaslik)
      fd.append('blokDuzenle', this.currentID)
      axios
          .post(store.state.POST_URL, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: function (progressEvent) {
              this.value = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 95),
              )
            }.bind(this),
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            if (res.data.result.status == 200) {
              this.$bvModal.hide('blokEdit')
              this.$store.dispatch('blok')
              this.selectedFile = [];
              (this.value = 0),
                  (this.blokTuru = {
                    title: 'Ana Sayfa',
                    id: 1,
                  }),
                  (this.fotoKonum = {
                    title: 'Sol',
                    id: 1,
                  }),
                  (this.fotoGenislik = '570'),
                  (this.fotoYukseklik = '300'),
                  (this.baslik = ''),
                  (this.content = ''),
                  (this.blokLink = ''),
                  (this.linkYazisi = '')
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
            } else {
              this.value = 0
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'warning',
                  },
                })
              }
            }
          })
          .catch(err => {
            this.value = 0
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'warning',
                },
              })
            }
          })
    },
    editOn(blokID) {
      this.currentID = blokID
      this.$store.dispatch('blokEdit', { blokID })
    },
    editHide() {
      this.$bvModal.hide('blokEdit')
      this.$store.commit('clearEditBlok')
      this.currentID = ''
    },
    onUpload() {
      const fd = new FormData()
      fd.append('resim', this.selectedFile[0] || '')
      fd.append('crm_token', localStorage.getItem('otelUserToken'))
      fd.append('serviceName', 'web')
      fd.append('methodName', 'blokYonetimi')
      fd.append('blokTuru', this.blokTuru.id)
      fd.append('fotoKonum', this.fotoKonum.id)
      fd.append('g', this.fotoGenislik)
      fd.append('y', this.fotoYukseklik)
      fd.append('baslik', this.baslik)
      fd.append('link', store.state.POST_URL)
      fd.append('icerik', this.content)
      fd.append('linkBaslik', this.linkYazisi)
      fd.append('blokKaydi', true)
      axios
          .post(store.state.POST_URL, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: function (progressEvent) {
              this.value = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 95),
              )
            }.bind(this),
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.$store.dispatch('blok')
            this.selectedFile = [];
            (this.value = 0),
                (this.blokTuru = {
                  title: 'Ana Sayfa',
                  id: 1,
                }),
                (this.fotoKonum = {
                  title: 'Sol',
                  id: 1,
                }),
                (this.fotoGenislik = '570'),
                (this.fotoYukseklik = '300'),
                (this.baslik = ''),
                (this.content = ''),
                (this.blokLink = ''),
                (this.linkYazisi = '')
            this.count++
            const mesaj = this.$store.getters.notificationSettings(res.data)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch((err, position = localStorage.getItem('otelSuccessReturn')) => {
            this.value = 0
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'warning',
                  },
                },
                { position },
            )
          })
    },
    blokSil(val) {
      this.$store
          .dispatch('blokSil', { id: val })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    onFileSelected(event) {
      this.selectedFile.push(event.target.files[0])
    },
    editOnFileSelected(event) {
      this.editSelectedFile.push(event.target.files[0])
    },
    formatNames(files) {
      return files.length === 1
          ? files[0].name
          : `${files.length} dosya seçildi`
    },
    editFormatNames(files) {
      return files.length === 1
          ? files[0].name
          : `${files.length} dosya seçildi`
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
[dir] .table th,
[dir] .table td {
  max-width: 160px !important;
}

.row {
  justify-content: end !important;
}

[dir] .table th,
[dir] .table td {
  padding: 3px;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.card-body {
  padding: 10px 15px;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #009879;
  color: white;
}
</style>
